<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <form
    v-if="data.fieldsets?.length > 0"
    ref="formRef"
    class="form-controller"
    @submit.prevent="onSubmit"
  >
    <fieldset
      v-for="fieldset of data.fieldsets"
      :key="fieldset._uid"
      class="form-fieldset"
      :class="{ 'is-two-column': fieldset.is_two_column }"
    >
      <div
        v-if="fieldset.title"
        class="form-fieldset__title"
      >
        {{ fieldset.title }}
      </div>

      <AtomRichtext
        v-if="fieldset.text?.content[0].content"
        class="form-fieldset__text"
        :data="fieldset"
      />

      <div
        v-for="fieldGroup of fieldset.field_groups"
        :key="fieldGroup._uid"
        class="form-fieldgroup"
      >
        <component
          :is="field.component"
          v-for="field of fieldGroup.fields"
          :key="field._uid"
          :data="field"
          :prefill-value="getPrefillData(field.field_key)"
          :class="field.layout"
          @emit-value="setData"
          @has-errors="setSubmitValidity"
        />
      </div>
    </fieldset>

    <AtomButton v-if="data.button && data.button[0]" :is-loading="isLoading" :data="data.button[0]" button-type="submit" appereance="primary" />
    <slot v-else />

    <FormConfirmation
      v-if="data.confirmation_popup?.length === 1"
      :data="data.confirmation_popup[0]"
      :is-open="isConfirmationOpen"
    />
    <!-- Honeypot Inputfield -->
    <input
      ref="honeypotRef"
      type="checkbox"
      name="contact_me_by_fax_only"
      value="1"
      style="display: none !important"
      tabindex="-1"
      autocomplete="off"
    />
  </form>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
    prefillData: {
        type: [Object, Boolean],
        default: false,
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
});

const getPrefillData = (key) => {
    if (!props.prefillData) return '';
    return props.prefillData[key];
};

/*
  Prevent submit on errors
*/
const emit = defineEmits(['onSubmit', 'hasErrors', 'hasContent']);

const submitDisabled = ref(false);
const fieldErrors = reactive([]);
const setSubmitValidity = (hasError) => {
    if (hasError) {
        fieldErrors.push(true);
    } else if (fieldErrors.length > 0) {
        fieldErrors.pop();
    }
};

watch(() => fieldErrors.length, () => {
    if (fieldErrors.length === 0) {
        submitDisabled.value = false;
        emit('hasErrors', false);
    } else {
        submitDisabled.value = true;
        emit('hasErrors', true);
    }
});

/*
    Confirmation Popup
*/
const needsConfirmation = computed(() => props?.data?.confirmation_popup?.length === 1);
/*
    Collect FormData from input fields
*/
const formData = ref({});

const setData = (values) => {
    if (Object.keys(values).length === 0) {
        emit('hasContent', false);
    } else {
        emit('hasContent', true);
    }
    formData.value[values.key] = values.value;
};

/*
    Emit collceted data to parent onSubmit
*/
const { alertConfirmation, isConfirmationOpen } = useFormConfirmation();

/*
    Honeypot
*/
const honeypotRef = ref('honeypotRef');

const onSubmit = async () => {
    /* Check HoneyPot */
    if (honeypotRef.value.checked) {
        return;
    }

    /* Add data to submit values to be able to handle success and error messages */
    formData.value.statusData = {
        success_text: props.data.success_text,
        error_text: props.data.fallback_error_text,
    };

    /* Check for confirmation popup */
    if (needsConfirmation.value) {
        const result = await alertConfirmation();
        if (!result) return;
    }

    /* Emit */
    if (!submitDisabled.value) {
        emit('onSubmit', formData.value);
    }
};

/**
 * Check if all required fields are filled
 * @returns {boolean}
 */
const allRequiredFieldsFilled = () => {
    let isFilled = true;
    props.data.fieldsets?.forEach((fieldset) => {
        fieldset.field_groups.forEach((fieldGroup) => {
            fieldGroup.fields.forEach((field) => {
                if (field.is_required && !formData.value[field.field_key]) {
                    isFilled = false;
                }
            });
        });
    });

    return isFilled;
};

/**
 * Check if all required fields are filled on component mount
 */
onMounted(() => {
    if (props.data.fieldsets?.length > 0) {
        submitDisabled.value = !allRequiredFieldsFilled();
        emit('hasErrors', !allRequiredFieldsFilled());
    }
});

/**
 * Watch for changes in form data and trigger submit button state and error messages
 */
watch(() => formData.value, () => {
    submitDisabled.value = !allRequiredFieldsFilled();
    emit('hasErrors', !allRequiredFieldsFilled());
}, { deep: true });

/*
    Reset handler
*/
const formRef = ref('formRef');
const resetForm = () => {
    formData.value = {};
    formRef.value.reset();
};

defineExpose({
    resetForm,
});
</script>

<style lang="scss" scoped>
.form-controller {
    @include typo-font('regular');
    @include typo-size('p');
    @include fluid('margin-bottom', 40px, 120px);

    user-select: none;

    .app-footer-landingpage & {
        max-width: 500px;
        margin-bottom: 0;

        @include mobile {
            max-width: 100%;
        }
    }

    .shop-checkout-address-form-item & {
        @include fluid('margin-bottom', 30px, 30px);
    }

    &.is-checkout {
        @include mobile {
            margin-bottom: 33px;
        }
    }
}

.form-fieldset__title {
    @include typo-font('regular');
    margin-bottom: 20px;
    font-size: 26px;

    .is-two-column & {
        grid-column-end: span 2;

        @include mobile {
            grid-column-end: span 1;
        }
    }
}

.form-fieldset {
    @include fluid('grid-column-gap', 20px, 10px);
    display: grid;
    padding: 0;
    grid-template-columns: repeat(1, 1fr);

    .form-fieldset__title {
        color: $C_GREEN_DARK;
    }

    &.is-two-column {
        grid-template-columns: repeat(2, 1fr);
        .form-fieldset__text {
            @include fluid('margin-bottom', 40px, 40px);

            grid-column-end: span 2;

            @include mobile {
                grid-column-end: span 1;
            }
        }

        @include mobile {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &:not(:first-child) {
        .form-fieldset__title {
            @include fluid('margin-top', 20px, 40px);
        }
    }

}

.form-fieldgroup {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}
</style>
